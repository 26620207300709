export class User {

  id: string
  username: string
  email: string
  firstName: string
  lastName: string
  profession: string
  firstContact: string           
  mobileNumber: string
  city: string
  country: string
  address: string
  cap: string
  state: string
  profileImageFileName: string
  password: string

  constructor(  id: string,
                username: string,
                email: string,
                firstName: string,
                lastName: string,
                profession: string,
                firstContact: string,                
                mobileNumber: string,
                city: string,
                country: string,
                address: string,
                cap: string,
                state: string,
                profileImageFileName: string,
                password: string)
  {
    this.id = id
    this.username = username
    this.email = email
    this.firstName = firstName
    this.lastName = lastName
    this.profession = profession
    this.firstContact = firstContact           
    this.mobileNumber = mobileNumber
    this.city = city
    this.country = country
    this.address = address
    this.cap = cap
    this.state = state
    this.profileImageFileName = profileImageFileName
    this.password = password
  }


}
