import { uuid } from 'vue-uuid';

export class DossierDocument {
  id: string;
  name?: string;
  text?: string;
  children: DossierDocument[];
  parent?: DossierDocument;
  isDossier: boolean;
  selected: boolean;

  constructor(
    id: string,
    name: string | undefined,
    text: string | undefined,
    children: DossierDocument[],
    parent: DossierDocument | undefined,
    isDossier: boolean,
    selected: boolean
  ) {
    this.id = id;
    this.name = name;
    this.text = text;
    this.children = children;
    this.parent = parent;
    this.isDossier = isDossier;
    this.selected = selected;
  }

  addDoc(doc: DossierDocument) {
    this.children?.push(doc);
  }

  removeDoc(doc: DossierDocument, children: DossierDocument[] | undefined) {
    if (children) {
      for (const c of children) {
        if (c.id == doc.id) {
          children.splice(children.indexOf(doc), 1);
        }
        this.removeDoc(doc, c.children);
      }
    }
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }

  getAllChildren(children: DossierDocument[]) {
    if (this.children) {
      for (const c of this.children) {
        if (!c.isDossier) {
          children.push(c);
        }
        c.getAllChildren(children);
      }
    }
    return children;
  }

  getAllSelectedChildren(children: DossierDocument[]): DossierDocument[] {
    if (this.children) {
      for (const c of this.children) {
        if (!c.isDossier && c.selected) {
          children.push(c);
        }
        c.getAllSelectedChildren(children);
      }
    }
    return children;
  }

  initFromJson(jsonObject: any) {
    //const [key, value] = jsonObject;
    // const jsonObject = JSON.parse(json);
    this.id = jsonObject.id;
    this.name = jsonObject.name;
    this.isDossier = jsonObject.isDossier;
    // console.log(jsonObject.children);
    if (jsonObject.children) {
      Object.entries(jsonObject.children).forEach((entry) => {
        const dossierDocument = new DossierDocument(
          uuid.v1(),
          undefined,
          undefined,
          [],
          this,
          false,
          false
        );
        const [key, value] = entry;
        dossierDocument.initFromJson(value);
        this.addDoc(dossierDocument);
        /*
      dossierDocument.initFromJson(JSON.stringify(entry));
      const [key, value] = entry;
      console.log(`${key}: ${value}`);*/
        //this.addDoc(dossierDocument);
      });
    }
  }

  clone(): DossierDocument {
    return new DossierDocument(
      this.id,
      this.name,
      this.text,
      this.children,
      this.parent,
      this.isDossier,
      this.selected
    );
  }
}
