import { DossierDocument } from '@/models/dossier.document.model';
import { uuid } from 'vue-uuid';

export class Dossier {
  username?: string;
  children: DossierDocument[];

  constructor(username: string | undefined, children: DossierDocument[]) {
    this.username = username;
    this.children = children;
  }

  addDoc(doc: DossierDocument) {
    this.children?.push(doc);
  }

  removeDoc(doc: DossierDocument, children: DossierDocument[] | undefined) {
    if (children) {
      for (const c of children) {
        if (c.id == doc.id) {
          children.splice(children.indexOf(doc), 1);
        }
        this.removeDoc(doc, c.children);
      }
    }
  }

  removeDossier(doc: DossierDocument, children: DossierDocument[] | undefined) {
    if (children) {
      for (const c of children) {
        if (c.id == doc.id) {
          children.splice(children.indexOf(doc), 1);
        }
        this.removeDoc(doc, c.children);
      }
    }
  }

  hasDossier() {
    if (this.children) {
      for (const c of this.children) {
        if (c.isDossier) {
          return true;
        }
      }
    }
    return false;
  }

  getAllChildren(children: DossierDocument[]): DossierDocument[] {
    if (this.children) {
      for (const c of this.children) {
        if (!c.isDossier) {
          children.push(c);
        }
        c.getAllChildren(children);
      }
    }
    return children;
  }

  getAllSelectedChildren(children: DossierDocument[]): DossierDocument[] {
    if (this.children) {
      for (const c of this.children) {
        if (!c.isDossier && c.selected) {
          children.push(c);
        }
        c.getAllSelectedChildren(children);
      }
    }
    return children;
  }

  initFromJson(json: string) {
    const jsonObject = JSON.parse(json);
    this.username = jsonObject.username;
    Object.entries(jsonObject.children).forEach((entry) => {
      const dossierDocument = new DossierDocument(
        uuid.v1(),
        undefined,
        undefined,
        [],
        undefined,
        false,
        false
      );
      const [key, value] = entry;
      dossierDocument.initFromJson(value);
      this.addDoc(dossierDocument);
    });
  }
}
