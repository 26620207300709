import { createStore } from 'vuex';

const store = createStore({
  state: {
    crmInfo: undefined,
    searchData: undefined,
    searchResults: undefined,
    hideIntro: undefined,
    isJuranews: undefined,
    docId: undefined,
  },
  mutations: {
    crmInfo(state, crmInfo) {
      state.crmInfo = crmInfo;
    },
    searchData(state, searchData) {
      state.searchData = searchData;
    },
    searchResults(state, searchResults) {
      state.searchResults = searchResults;
    },
    hideIntro(state, hideIntro) {
      state.hideIntro = hideIntro;
    },
    isJuranews(state, isJuranews) {
      state.isJuranews = isJuranews;
    },
    docId(state, docId) {
      state.docId = docId;
    },
  },
  actions: {
    crmInfo(context, crmInfo) {
      context.commit('crmInfo', crmInfo);
    },
    searchData(context, searchData) {
      context.commit('searchData', searchData);
    },
    searchResults(context, searchResults) {
      context.commit('searchResults', searchResults);
    },
    hideIntro(context, hideIntro) {
      context.commit('hideIntro', hideIntro);
    },
    isJuranews(context, isJuranews) {
      context.commit('isJuranews', isJuranews);
    },
    docId(context, docId) {
      context.commit('docId', docId);
    },
  },
  getters: {
    crmInfo: (state) => {
      return state.crmInfo;
    },
    searchData: (state) => {
      return state.searchData;
    },
    searchResults: (state) => {
      return state.searchResults;
    },
    hideIntro: (state) => {
      return state.hideIntro;
    },
    isJuranews: (state) => {
      return state.isJuranews;
    },
    docId: (state) => {
      return state.docId;
    },
  },
});

export default store;
