import axios from 'axios';
import { User } from '@/models/crm/user.model';
import { Purchase } from '@/models/crm/purchase.model';
import { Service } from '@/models/crm/service.model';
import { Picklist } from '@/models/picklist.model';
import {
  Categories,
  Sections,
  OrderingTypes,
  SearchTypes,
} from '@/models/enums.model';
import { Testata } from '@/models/testata.model';
import { Mail } from '@/models/mail.model';
import moment from 'moment';
import firebase from 'firebase/app';
import firebaseConfig from '@/firebaseConfig.js';
import { Dossier } from '@/models/dossier.model';
import { DossierDocument } from '@/models/dossier.document.model';

/*let firebaseMessaging: firebase.messaging.Messaging;
try {
  const app = firebase.initializeApp(firebaseConfig);
  firebaseMessaging = app.messaging();
} catch (err) {
  console.log('JnService(FCM): " + err);
}*/

class JuraNewsService {
  async getCrmUser(username: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/contact?username=' +
      username;
    try {
      const crmUser = (
        await axios.get(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
        })
      ).data;
      const user = new User(
        crmUser.id,
        crmUser.cf_contacts_username,
        crmUser.email,
        crmUser.firstname,
        crmUser.lastname,
        crmUser.cf_contacts_profession,
        crmUser.cf_contacts_firstContact,
        crmUser.mobileNumber,
        crmUser.mailingcity,
        crmUser.mailingcountry,
        crmUser.mailingaddress,
        crmUser.mailingzip,
        crmUser.mailingstate,
        crmUser.cf_contacts_profileimagefilename,
        ''
      );
      return user;
    } catch (err) {
      return err.response.data;
    }
  }

  async login(username: string, password: string, maxNumSessions: number, productId: number) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/login';
    try {
      const res = (
        await axios.post(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: { username: username, password: password, sessionTimeout: process.env.VUE_APP_SESSION_TIMEOUT, maxNumSessions: maxNumSessions, productId: productId },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async logout(username: string, idSession: string, productId: number) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/logout';
    try {
      const res = (
        await axios.post(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: { username: username, idSession: idSession, productId: productId },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async resetPassword(username: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/password/reset';
    try {
      const res = (
        await axios.post(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: {
            username: username,
          },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async changePassword(
    username: string,
    currentPassword: string,
    newPassword: string
  ) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/password';
    try {
      const res = (
        await axios.put(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
          data: {
            username: username,
            currentPassword: currentPassword,
            newPassword: newPassword,
          },
        })
      ).data;
      return res;
    } catch (err) {
      return err.response.data;
    }
  }

  async getActivePurchaseOrder(contactId: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/active-purchase-order?contactId=' +
      contactId;
    try {
      const poRes = (
        await axios.get(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
        })
      ).data;
      let purchaseOrder: Purchase | undefined;
      poRes.forEach(function (e: any) {
        console.log(e)
        const now = moment();
        if (e.postatus == 'Received Shipment' && now.isBefore(moment(e.cf_purchaseorder_enddate, 'YYYY-MM-DD'))) {
          let serviceId = undefined
          if (Array.isArray(e.lineItems)) {
            serviceId = e.lineItems[0].productid
          }
          else {
            serviceId = JSON.parse(JSON.stringify(Object.entries(e.lineItems)[0][1])).productid
          }
          purchaseOrder = new Purchase(
            e.subject,
            serviceId,
            undefined,
            e.cf_purchaseorder_enddate
          );
        }
      });
      return purchaseOrder;
    } catch (err) {
      return err.response.data;
    }
  }

  async getService(serviceId: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/service?serviceId=' +
      serviceId;
    try {
      const serviceRes = (
        await axios.get(url, {
          headers: {
            apikey: process.env.VUE_APP_API_KEY,
          },
        })
      ).data;
      let service: Service | undefined;
      return new Service(serviceRes.id, serviceRes.description);
    } catch (err) {
      return err.response.data;
    }
  }

  async sendmail(mail: Mail) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/sendmail';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: mail,
      });
      return result.data;
    } catch (err) {
      console.log(err);
      return err.response.data;
    }
  }

  async getPicklist(entityType: string, fieldName: string) {
    const picklist: Picklist[] = [];
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
      '/picklist?entityType=' +
      entityType +
      '&fieldName=' +
      fieldName;
    const picklistRes = (
      await axios.get(url, {
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      })
    ).data;
    picklistRes.forEach(function (e: any) {
      picklist.push(new Picklist({ label: e.label, value: e.value }));
    });
    return picklist;
  }

  async registerUser(user: User) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/user';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: user,
      });
      return result.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async purchase(purchase: Purchase) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/purchase';
    try {
      const result = await axios({
        method: 'post',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
        data: purchase,
      });
      return result.data;
    } catch (err) {
      return err.response.data;
    }
  }

  async fcmRegisterTopic(token: string, topic: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-register-topic';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: { token: token, topic: topic },
    });
    return result;
  }

  async fcmUnregisterTopic(token: string, topic: string) {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-unregister-topic';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: { token: token, topic: topic },
    });
    return result;
  }

  async fcmGetTokens(username: string, client: string) {
    try {
      const url =
        process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
        '/fcm-token?username=' +
        username +
        '&client=' +
        client;
      const result = await axios({
        method: 'get',
        url: url,
        headers: {
          apikey: process.env.VUE_APP_API_KEY,
        },
      });
      return result.data;
    } catch (err) {
      return err.response;
    }
  }

  async fcmSaveToken(username: string, client: string, token: string) {
    const url = process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/fcm-token';
    const result = await axios({
      method: 'post',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
      data: { username: username, client: client, token: token },
    });
    return result.data;
  }

  async getDossier(username: string): Promise<Dossier> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/' + username;
    const dossier = new Dossier(username, []);
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
    });
    if (result.status == 200) {
      dossier.initFromJson(JSON.stringify(result.data.structure));
    }
    return dossier;
  }

  async getDossierDocument(id: string): Promise<DossierDocument> {
    const url =
      process.env.VUE_APP_JURANEWS_JN_SERVICES_URL + '/dossier/document/' + id;
    const result = await axios({
      method: 'get',
      url: url,
      headers: {
        apikey: process.env.VUE_APP_API_KEY,
      },
    });
    const dossier = new DossierDocument(
      result.data.id,
      result.data.name,
      result.data.text,
      [],
      undefined,
      false,
      false
    );
    return dossier;
  }

  getQueryTypes(): Picklist[] {
    const tipiDocumento: Picklist[] = [];
    tipiDocumento.push(new Picklist({ value: 'OR', label: 'OR' }));
    tipiDocumento.push(new Picklist({ value: 'AND', label: 'AND' }));
    tipiDocumento.push(new Picklist({ value: 'EXACT', label: 'Esatto' }));
    return tipiDocumento;
  }

  getTipiDocumento(): Picklist[] {
    const tipiDocumento: Picklist[] = [];
    tipiDocumento.push(
      new Picklist({ value: Categories.ULTIME_NEWS, label: 'News' })
    );
    tipiDocumento.push(
      new Picklist({
        value: Categories.SINTESI_GIURISPRUDENZIALI,
        label: 'Sintesi Giurisprudenziale',
      })
    );
    tipiDocumento.push(
      new Picklist({
        value: Categories.SCHEDA_AUTORALE,
        label: 'Scheda Autorale',
      })
    );
    tipiDocumento.push(
      new Picklist({ value: Categories.COMMENTI, label: 'Commento' })
    );
    return tipiDocumento;
  }

  getTipiOrdinamento(): Picklist[] {
    const tipiOrdinamento: Picklist[] = [];
    tipiOrdinamento.push(
      new Picklist({ value: OrderingTypes.SCORE, label: 'Rilevanza' })
    );
    tipiOrdinamento.push(
      new Picklist({
        value: OrderingTypes.DATA_CREAZIONE,
        label: 'Cronologico',
      })
    );
    return tipiOrdinamento;
  }

  getTipiRicercaGlobale(): Picklist[] {
    const tipiRicercaGlobale: Picklist[] = [];
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.ALL, label: 'Tutti' })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.NEWS, label: 'News' })
    );
    tipiRicercaGlobale.push(
      new Picklist({
        value: SearchTypes.AREE_TEMATICHE,
        label: 'Aree Tematiche',
      })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.BANCA_DATI, label: 'Banca Dati' })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.MASSIMARIO, label: 'Massimario' })
    );
    tipiRicercaGlobale.push(
      new Picklist({ value: SearchTypes.CODICI, label: 'Codici' })
    );
    return tipiRicercaGlobale;
  }

  getNumeroRisultati(): Picklist[] {
    const numeroRisultati: Picklist[] = [];
    numeroRisultati.push(new Picklist({ value: '20', label: '20' }));
    numeroRisultati.push(new Picklist({ value: '50', label: '50' }));
    numeroRisultati.push(new Picklist({ value: '100', label: '100' }));
    return numeroRisultati;
  }

  getAnni(): Picklist[] {
    const anni: Picklist[] = [];
    for (let i = Number(moment().format('YYYY')); i >= 1900; i--) {
      anni.push(new Picklist({ value: String(i), label: String(i) }));
    }
    return anni;
  }

  getTestata(id: string): Testata {
    console.log("ID: " + id)
    if (id == Categories.DIRITTO_SOCIETARIO) {
      return new Testata({
        id: id,
        name: 'Diritto Societario',
        title: '',
        description:
          "Selezione delle tematiche più aggiornate relative all'impresa e alle società.",
        image: '/img/aree-tematiche/testata-diritto-societario.jpg',
      });
    } else if (id == Categories.DIRITTO_FALLIMENTARE) {
      return new Testata({
        id: id,
        name: 'Diritto Fallimentare',
        title: '',
        description:
          'Tutti gli ultimi aggiornamenti relativi al fallimento e alle procedure concorsuali',
        image: '/img/aree-tematiche/testata-diritto-fallimentare.jpg',
      });
    } else if (id == Categories.DIRITTO_TRIBUTARIO) {
      return new Testata({
        id: id,
        name: 'Diritto Tributario - Fiscale - Doganale',
        title: '',
        description:
          'Aspetti tributari e fiscali e le problematiche doganali esaminati dai nostri consulenti.',
        image: '/img/aree-tematiche/testata-diritto-tributario.png',
      });
    } else if (id == Categories.DIRITTO_PENALE) {
      return new Testata({
        id: id,
        name: 'Diritto Penale',
        title: '',
        description:
          'Tutti gli ultimi aggiornamenti relativi al diritto penale',
        image: '/img/aree-tematiche/testata-diritto-penale.jpg',
      });
    } else if (id == Categories.DIRITTO_DEL_LAVORO) {
      return new Testata({
        id: id,
        name: 'Diritto del Lavoro',
        title: 'Il diritto del lavoro a portata di click',
        description:
          'Panoramica completa degli aspetti legali e giurisprudenziali, compreso il rinvio alle circolari che semplificano l’applicazione delle norme',
        image: '/img/aree-tematiche/testata-diritto-lavoro.png',
      });
    } else if (id == Categories.DIRITTO_AMMINISTRATIVO) {
      return new Testata({
        id: id,
        name: 'Diritto Amministrativo',
        title: '',
        description:
          "Tutte le risposte ai quesiti relativi all'impresa, alle società, al fallimento e procedure concorsuali",
        image: '/img/aree-tematiche/testata-diritto-amministrativo.jpg',
      });
    } else if (id == Categories.ASSICURAZIONI) {
      return new Testata({
        id: id,
        name: 'Assicurazioni - Risarcimento Danni',
        title: '',
        description:
          'Le notizie più recenti in materia di assicurazione e risarcimento del danno',
        image: '/img/aree-tematiche/testata-assicurazioni.png',
      });
    } else if (id == Categories.DIRITTO_FAMIGLIA) {
      return new Testata({
        id: id,
        name: 'Diritto Famiglia - Successioni',
        title: '',
        description:
          'Tutte le ultime novità sul diritto di famiglia e le successioni',
        image: '/img/aree-tematiche/testata-diritto-di-famiglia.png',
      });
    } else if (id == Categories.MERCATI_IMMOBILIARI) {
      return new Testata({
        id: id,
        name: 'Mercati immobiliari',
        title: '',
        description:
          'Approfondimenti in materia di mercati immobiliari e strumenti contrattuali',
        image: '/img/aree-tematiche/testata-mercato-immobiliare.jpg',
      });
    } else if (id == Categories.PRIVACY) {
      return new Testata({
        id: id,
        name: 'Protezione dei dati personali',
        title: '',
        description:
          'La materia della privacy accuratamente trattata in tutti i suoi aspetti',
        image: '/img/aree-tematiche/testata-privacy.jpg',
      });
    }
    else if (id == Categories.DIRITTO_NUOVE_TECNOLOGIE) {
      return new Testata({
        id: id,
        name: 'Diritto delle nuove tecnologie',
        title: '',
        description:
          "L'evoluzione della giurisprudenza tra diritto ed etica",
        image: '/img/aree-tematiche/testata-diritto-nuove-tecnologie.jpg',
      });
    }
    else if (id == Categories.CONTRATTUALISTICA_DIRITTO_PRIVATO) {
      return new Testata({
        id: id,
        name: 'Contrattualistica di diritto privato',
        title: '',
        description:
          'Aspetti legali e giurisprudenziali della contrattualistica di diritto privatoi',
        image: '/img/aree-tematiche/testata-contratti.jpg',
      });
    } else if (id == Categories.BANCARIO_MERCATI_FINANZIARI) {
      return new Testata({
        id: id,
        name: 'Bancario e mercati finanziari',
        title: '',
        description:
          'Il diritto bancario e la disciplina dei mercati finanziari aggiornati e commentati',
        image: '/img/aree-tematiche/testata-mercati-finanziari.png',
      });
    } else if (id == Categories.CYBERSICUREZZA) {
      return new Testata({
        id: id,
        name: 'Cybersicurezza',
        title: '',
        description: 'Testo',
        image: '/img/testata-news.png',
      });
    } else if (id == Categories.PROCESSO_TELEMATICO) {
      return new Testata({
        id: id,
        name: 'Diritto Processuale Telematico',
        title: '',
        description:
          'Aspetti normativi e riforme del processo telematico e del diritto processuale, con l’analisi delle ultime pronunce giurisprudenziali',
        image: '/img/aree-tematiche/testata-processo-telematico.jpg',
      });
    } else if (id == Categories.ULTIME_NEWS) {
      return new Testata({
        id: id,
        name: 'News',
        title: '',
        description:
          '',
        image: '/img/aree-tematiche/testata-interna.jpg',
      });
    } else if (id == Sections.BANCA_DATI) {
      return new Testata({
        id: id,
        name: 'Banca Dati',
        title: '',
        description:
          '',
        image: '/img/aree-tematiche/testata-interna.jpg',
      });
    } else if (id == Sections.COMMON_SEARCH_RESULT) {
      return new Testata({
        id: id,
        name: 'Ricerca globale',
        title: '',
        description:
          '',
        image: '/img/aree-tematiche/testata-interna.jpg',
      });
    } else if (id == Sections.REAL_TIME) {
      return new Testata({
        id: id,
        name: 'Real Time',
        title: '',
        description:
          '',
        image: '/img/aree-tematiche/testata-interna.jpg',
      });
    }

    return new Testata({
      id: '0',
      name: '',
      title: '',
      description: '',
      image: '',
    });
  }
}
export const JnService = new JuraNewsService();
