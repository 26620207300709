import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import * as eventBus from '../services/event.bus.js';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/views/Intro.vue'),
  },
  {
    path: '/main',
    component: () => import('@/views/MainTabs.vue'),
    children: [
      {
        path: '',
        redirect: '/main/home',
      },
      {
        path: 'home',
        component: () => import('@/views/home/Home.vue'),
        name: 'Home',
      },
      {
        path: 'aree-tematiche',
        component: () => import('@/views/aree-tematiche/AreeTematiche.vue'),
        name: 'Aree Tematiche',
      },
      {
        path: 'real-time',
        component: () => import('@/views/real-time/RealTime.vue'),
        name: 'Real Time',
      },
      {
        path: 'banca-dati',
        component: () => import('@/views/banca-dati/BancaDati.vue'),
        name: 'Banca Dati',
      },
      {
        path: 'search',
        component: () => import('@/views/search/Search.vue'),
        name: 'Ricerca',
      },
    ],
  },
  {
    path: '/news',
    component: () => import('@/views/news/News.vue'),
    name: 'News',
  },
  {
    path: '/codici',
    component: () => import('@/views/codici/Codici.vue'),
    name: 'Codici',
  },
  {
    path: '/video',
    component: () => import('@/views/video/Video.vue'),
    name: 'Video',
  },
  {
    path: '/massimario',
    component: () => import('@/views/massimario/Massimario.vue'),
    name: 'Massimario',
  },
  {
    path: '/formulario',
    component: () => import('@/views/formulario/Formulario.vue'),
    name: 'Formulari',
  },
  {
    path: '/dossier',
    component: () => import('@/views/dossier/Dossier.vue'),
    name: 'Fascicoli',
  },
  {
    path: '/notifiche',
    component: () => import('@/views/notifiche/Notifiche.vue'),
    name: 'Notifiche',
  },
  {
    path: '/login',
    component: () => import('@/views/auth/Login.vue'),
    name: 'Login',
  },
  {
    path: '/register',
    component: () => import('@/views/auth/Register.vue'),
    name: 'Register',
  },
  {
    path: '/docs/juranews/:id',
    component: () => import('@/views/common/JuranewsDoc.vue'),
    name: 'Documento - JuraNews',
  },
  {
    path: '/docs/juranet/:id',
    component: () => import('@/views/common/JuranetDoc.vue'),
    name: 'Documento - Banca Dati',
  },
  {
    path: '/docs/juranet/:id/:target/:type',
    component: () => import('@/views/common/JuranetDoc.vue'),
    name: 'Documento - Banca Dati - Frammento',
  },
  {
    path: '/main/aree-tematiche/:id',
    component: () => import('@/views/aree-tematiche/AreaTematica.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const auth = to.matched.some((record) => record.meta.auth);
  console.log(
    "Router - beforeEach. From '" + from.fullPath + "' to '" + to.fullPath + "'"
  );

  if (to.name == 'Fascicoli') {
    eventBus.default.$emit('reset');
  }

  next();
});

export default router;
