import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import { IonicVue } from '@ionic/vue';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import VueSocialSharing from 'vue-social-sharing';
import './registerServiceWorker';
import { createMetaManager } from 'vue-meta';
//import firebaseMessaging from './configFirebase.js';

// FONT e ICONE AWESOME
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faSitemap, faBook, faGavel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(fas, faSitemap, faBook, faGavel);

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';


/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import './scss/style.scss';

/* Theme variables */
import './theme/variables.scss';


const app = createApp(App)
  .use(store)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(VueSocialSharing)
  .use(createMetaManager())
  .component('fa', FontAwesomeIcon);

router.isReady().then(() => {
  app.mount('#app');
  defineCustomElements(window);
});
