import { User } from '@/models/crm/user.model';

export class Purchase {
  subject: string;
  serviceId: string;
  scadenza: string | undefined;
  user: User | undefined;

  constructor(
    subject: string,
    serviceId: string,
    user: User | undefined,
    scadenza: string | undefined
  ) {
    this.subject = subject;
    this.serviceId = serviceId;
    this.user = user;
    this.scadenza = scadenza;
  }
}
