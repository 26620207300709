export enum Access {
  PUBLIC = '1',
  REGISTERED = '2',
}

export enum SearchTypes {
  ALL = '0',
  NEWS = '1',
  AREE_TEMATICHE = '2',
  BANCA_DATI = '3',
  MASSIMARIO = '5',
  FORMULARIO = '6',
  CODICI = '7'
}

export enum QueryTypes {
  OR = 'OR',
  AND = 'AND',
  FRASE_ESATTA = 'EXACT',
}

export const News = ['9999999', '999999298'];
export const AreeTematiche = [
  '99999914',
  '999999289',
  '99999919',
  '99999915',
  '99999920',
  '99999921',
  '999999310',
  '999999311',
  '999999312',
  '999999313',
  '999999314',
  '999999469',
  '999999569',
  '999999493',
  '999999569'
];
export enum Video {
  PILLOLE_VIDEO = '999999661',
  APPROFONDIMENTI = '999999298',
  PODCAST = '999999662',
}
export const CategoriesVideo = [Video.PILLOLE_VIDEO, Video.APPROFONDIMENTI, Video.PODCAST];

export enum Massimario {
  CASSAZIONE_CIVILE = '999999590',
  CASSAZIONE_PENALE = '999999591',
  CASSAZIONE_MERITO = '999999621',
  CORTE_COSTITUZIONALE = '999999656',
  GIUSTIZIA_AMMINISTRATIVA = '999999659',
}

export enum Formulario {
  ULTIME_FORMULE = '999999654'
}

export enum FcmTopicsPlatform {
  PORTAL = 'portal',
  APP = 'app',
}
export enum FcmTopicsAreeTematiche {
  GENERALE = 'generale',
  DIRITTO_SOCIETARIO = 'diritto-societario',
  DIRITTO_FALLIMENTARE = 'diritto-fallimentare',
  DIRITTO_TRIBUTARIO = 'diritto-tributario',
  DIRITTO_PENALE = 'diritto-penale',
  DIRITTO_DEL_LAVORO = 'diritto-del-lavoro',
  DIRITTO_AMMINISTRATIVO = 'diritto-amministrativo',
  ASSICURAZIONI = 'assicurazioni',
  DIRITTO_DI_FAMIGLIA = 'diritto-famiglia',
  MERCATI_IMMOBILIARI = 'mercati-immobiliari',
  PRIVACY = 'privacy',
  CONTRATTUALISTICA_DIRITTO_PRIVATO = 'contrattualistica-diritto-privato',
  BANCARIO_MERCATI_FINANZIARI = 'bancario-mercati-finanziari',
  PROCESSO_TELEMATICO = 'processo-telematico',
  CYBERSICUREZZA = 'cybersicurezza',
}
export const FcmTopicsAreeTematicheList = [
  FcmTopicsAreeTematiche.GENERALE,
  FcmTopicsAreeTematiche.DIRITTO_SOCIETARIO,
  FcmTopicsAreeTematiche.DIRITTO_FALLIMENTARE,
  FcmTopicsAreeTematiche.DIRITTO_TRIBUTARIO,
  FcmTopicsAreeTematiche.DIRITTO_PENALE,
  FcmTopicsAreeTematiche.DIRITTO_DEL_LAVORO,
  FcmTopicsAreeTematiche.DIRITTO_AMMINISTRATIVO,
  FcmTopicsAreeTematiche.ASSICURAZIONI,
  FcmTopicsAreeTematiche.DIRITTO_DI_FAMIGLIA,
  FcmTopicsAreeTematiche.MERCATI_IMMOBILIARI,
  FcmTopicsAreeTematiche.PRIVACY,
  FcmTopicsAreeTematiche.CONTRATTUALISTICA_DIRITTO_PRIVATO,
  FcmTopicsAreeTematiche.BANCARIO_MERCATI_FINANZIARI,
  FcmTopicsAreeTematiche.PROCESSO_TELEMATICO,
  FcmTopicsAreeTematiche.CYBERSICUREZZA,
];

export enum Categories {
  COMMENTI = '9999998',
  ULTIME_NEWS = '9999999',
  SCHEDA_AUTORALE = '99999910',
  APPROFONDIMENTI = '999999298',
  CODICI = '999999316',
  MASSIME = '9999997',
  CONCORSI = '99999911',
  SINTESI_GIURISPRUDENZIALI = '99999912',
  DIRITTO_SOCIETARIO = '99999914',
  DIRITTO_FALLIMENTARE = '999999289',
  DIRITTO_TRIBUTARIO = '99999919',
  DIRITTO_PENALE = '999999469',
  DIRITTO_DEL_LAVORO = '99999915',
  DIRITTO_AMMINISTRATIVO = '999999569',
  ASSICURAZIONI = '99999920',
  DIRITTO_FAMIGLIA = '99999921',
  SINTESI_STORICO = '999999291',
  MERCATI_IMMOBILIARI = '999999311',
  PRIVACY = '999999310',
  CONTRATTUALISTICA_DIRITTO_PRIVATO = '999999312',
  BANCARIO_MERCATI_FINANZIARI = '999999313',
  PROCESSO_TELEMATICO = '999999314',
  CYBERSICUREZZA = '999999317',
  DIRITTO_NUOVE_TECNOLOGIE = '999999493'
}

interface CategoriesToRoutesInterface {
  [catId: string]: string;
}
export const CategoriesToRoutes: CategoriesToRoutesInterface = {};
CategoriesToRoutes['99999914'] = 'diritto-societario';
CategoriesToRoutes['999999289'] = 'diritto-fallimentare';
CategoriesToRoutes['99999919'] = 'diritto-tributario';
CategoriesToRoutes['999999469'] = 'diritto-penale';
CategoriesToRoutes['99999915'] = 'diritto-del-lavoro';
CategoriesToRoutes['999999569'] = 'diritto-amministrativo';
CategoriesToRoutes['99999920'] = 'assicurazioni';
CategoriesToRoutes['99999921'] = 'diritto-famiglia';
CategoriesToRoutes['999999311'] = 'mercati-immobiliari';
CategoriesToRoutes['999999310'] = 'privacy';
CategoriesToRoutes['999999312'] = 'contrattualistica-diritto-privato';
CategoriesToRoutes['999999313'] = 'bancario-mercati-finanziari';
CategoriesToRoutes['999999314'] = 'processo-telematico';
CategoriesToRoutes['999999317'] = 'cybersicurezza';
CategoriesToRoutes['999999493'] = 'diritto-nuove-tecnologie';

interface RoutesToCategoriesInterface {
  [route: string]: string;
}
export const RoutesToCategories: RoutesToCategoriesInterface = {};
RoutesToCategories['diritto-societario'] = Categories.DIRITTO_SOCIETARIO;
RoutesToCategories['diritto-fallimentare'] = Categories.DIRITTO_FALLIMENTARE;
RoutesToCategories['diritto-tributario'] = Categories.DIRITTO_TRIBUTARIO;
RoutesToCategories['diritto-penale'] = Categories.DIRITTO_PENALE;
RoutesToCategories['diritto-del-lavoro'] = Categories.DIRITTO_DEL_LAVORO;
RoutesToCategories['diritto-amministrativo'] =
  Categories.DIRITTO_AMMINISTRATIVO;
RoutesToCategories['assicurazioni'] = Categories.ASSICURAZIONI;
RoutesToCategories['diritto-famiglia'] = Categories.DIRITTO_FAMIGLIA;
RoutesToCategories['mercati-immobiliari'] = Categories.MERCATI_IMMOBILIARI;
RoutesToCategories['privacy'] = Categories.PRIVACY;
RoutesToCategories['contrattualistica-diritto-privato'] =
  Categories.CONTRATTUALISTICA_DIRITTO_PRIVATO;
RoutesToCategories['bancario-mercati-finanziari'] =
  Categories.BANCARIO_MERCATI_FINANZIARI;
RoutesToCategories['processo-telematico'] = Categories.PROCESSO_TELEMATICO;
RoutesToCategories['cybersicurezza'] = Categories.CYBERSICUREZZA;
RoutesToCategories['diritto-nuove-tecnologie'] = Categories.DIRITTO_NUOVE_TECNOLOGIE;

export enum EmailTemplates {
  CONTATTO = 'contatto',
  ATTIVAZIONE = 'attivazione',
  REGISTRAZIONE = 'registrazione',
  RICHIESTA_TRIAL = 'richiesta-trial',
}

export enum CategoriesJuranet {
  // Documentazione - Normativa nazionale"
  DLS = '16',
  // Documentazione - Normativa regionale"
  DLR = '34',
  // Tribunale UE - Consiglio nazionale forense"
  CGDU = '71',
  // Prassi e decisioni"
  PA = '20',
  // Giurisprudenza - Merito"
  TDA = '40',
  // Giurisprudenza - TAR"
  TAR = '28',
  // Documentazione - Normativa europea"
  NC = '17',
  // Documentazione - Normativa internazionale"
  AI = '18',
  // Giurisprudenza - Consiglio di Stato"
  DCCS = '27',
  // Giurisprudenza - Corte costituzionale"
  DCCOST = '26',
  // Giurisprudenza - Corte di Giustizia UE"
  DCCG = '13',
  // Tribunale UE - Corte dei Conti"
  DCCC = '69',
  // Giurisprudenza - Corte di Cassazione"
  DCCASS = '19',
  // Tribunale UE - Commissioni tributarie"
  CT = '70',
  // PRASSI
  PRASSI_43 = '43',
  PRASSI_44 = '44',
  PRASSI_53 = '53',
  PRASSI_57 = '57',
  PRASSI_60 = '60',
  PRASSI_61 = '61',
  PRASSI_62 = '62',
  PRASSI_63 = '63',
  PRASSI_64 = '64',
  PRASSI_65 = '65',
  PRASSI_66 = '66',
  PRASSI_72 = '72',
  PRASSI_73 = '73',
  PRASSI_74 = '74',
  PRASSI_75 = '75',
}

export const CategoriesNormativa = [
  CategoriesJuranet.DLS,
  CategoriesJuranet.NC,
  CategoriesJuranet.AI,
];

export const CategoriesGiurisprudenza = [
  CategoriesJuranet.DCCOST,
  CategoriesJuranet.DCCASS,
  CategoriesJuranet.DCCS,
  CategoriesJuranet.TAR,
  CategoriesJuranet.DCCG,
  CategoriesJuranet.TDA,
];

export const CategoriesPrassiJuranet = [
  CategoriesJuranet.PRASSI_43,
  CategoriesJuranet.PRASSI_44,
  CategoriesJuranet.PRASSI_53,
  CategoriesJuranet.PRASSI_57,
  CategoriesJuranet.PRASSI_60,
  CategoriesJuranet.PRASSI_61,
  CategoriesJuranet.PRASSI_62,
  CategoriesJuranet.PRASSI_63,
  CategoriesJuranet.PRASSI_64,
  CategoriesJuranet.PRASSI_65,
  CategoriesJuranet.PRASSI_66,
  CategoriesJuranet.PRASSI_72,
  CategoriesJuranet.PRASSI_73,
  CategoriesJuranet.PRASSI_74,
  CategoriesJuranet.PRASSI_75,
];

export const CategoriesMassimario = [Massimario.CASSAZIONE_CIVILE, Massimario.CASSAZIONE_PENALE, Massimario.CASSAZIONE_MERITO]

export enum Sections {
  BANCA_DATI = '1000',
  COMMON_SEARCH_RESULT = '1001',
  REAL_TIME = '1002',
}

export enum OrderingTypes {
  SCORE = '0',
  DATA_CREAZIONE = '1',
  DATA_GAZZETTA = '2',
}

export const MONTH_SHORT_NAMES =
  'gen, feb, mar, apr, mag, giu, lug, ago, set, ott, nov, dic';

export const MONTH_NAMES =
  'gennaio, febbraio, marzo, aprile, maggio, giugno, luglio, agosto, settembre, ottobre, novembre, dicembre';

export const DAY_SHORT_NAMES = 'dom, lun, mar, mer, gio, ven, sab';

export const DAY_NAMES =
  'Domenica, Lunedì, Martedì, Mercoledì, Giovedì, Venerdì, Sabato, Domenica';
