
import {
  IonApp,
  IonContent,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonSplitPane,
  IonMenuToggle,
  IonIcon,
  IonLabel,
  IonTitle,
  IonHeader,
  IonItem,
  IonToolbar,
  IonItemDivider,
  IonText,
  IonCol,
  IonRow,
  popoverController,
} from "@ionic/vue";
import {
  logOut,
  home,
  newspaper,
  layers,
  logIn,
  library,
  timer,
  book,
  notifications,
  documents,
  film
} from "ionicons/icons";
import { useRouter } from "vue-router";
import { defineComponent, ref, onMounted, watch } from "vue";
import { addIcons } from "ionicons";
import { useCookie } from "@vue-composable/cookie";
import { Menu } from "@/models/menu.model";
import * as eventBus from "@/services/event.bus.js";
import { useStore } from "vuex";
import { isAndroid, isIOS, osVersion } from "mobile-device-detect";
import { JnService } from "@/services/jn.service";

addIcons({
  newspaper,
  "log-out": logOut,
  home,
  layers,
  "log-in": logIn,
  library,
  timer,
  book,
  notifications,
  documents,
  film
});

export default defineComponent({
  inheritAttrs: false,
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonMenuToggle,
    IonIcon,
    IonLabel,
    IonTitle,
    IonHeader,
    IonItem,
    IonToolbar,
    IonItemDivider,
    IonText,
    IonCol,
    IonRow,
  },
  setup() {
    const usernameCookie = useCookie("username");
    const paywallCookie = useCookie("paywall");
    const idSessionCookie = useCookie("SESSIONID");

    const isAuthenticated = ref(false);

    const version = ref(process.env.VUE_APP_VERSION);
    const environment = ref(process.env.VUE_APP_ENVIRONMENT);

    function isIosCompliant(): boolean {
      let isIosCompliant = true
      if (isIOS) {
        const major = Number(osVersion.split(".")[0])
        const minor = Number(osVersion.split(".")[1])
        const fix = Number(osVersion.split(".")[2])
        if (major < 16) {
          isIosCompliant = false
        }
        if (major == 16) {
          isIosCompliant = isIosCompliant && minor >= 4 && fix >= 1
        }
        if (major > 16) {
          isIosCompliant = true
        }
      }
      return isIosCompliant
    }

    const homeMenu = ref(
      new Menu({
        name: "Home",
        routeName: "Home",
        routePath: "/main",
        icon: "home",
        divider: false,
        visible: true,
      })
    );
    const newsMenu = ref(
      new Menu({
        name: "News",
        routeName: "News",
        routePath: "/news",
        icon: "newspaper",
        divider: false,
        visible: true,
      })
    );
    const areeTematicheMenu = ref(
      new Menu({
        name: "Aree Tematiche",
        routeName: "AreeTematiche",
        routePath: "/main/aree-tematiche",
        icon: "layers",
        divider: false,
        visible: true,
      })
    );
    const massimarioMenu = ref(
      new Menu({
        name: "Massimario",
        routeName: "Massimario",
        routePath: "/massimario",
        faIcon: "gavel",
        divider: false,
        visible: true,
      })
    );
    const formularioMenu = ref(
      new Menu({
        name: "Formulari",
        routeName: "Formulario",
        routePath: "/formulario",
        faIcon: "list",
        divider: false,
        visible: true,
      })
    );
    const realTimeMenu = ref(
      new Menu({
        name: "Real Time",
        routeName: "RealTime",
        routePath: "/main/real-time",
        icon: "timer",
        divider: false,
        visible: true,
      })
    );
    const bancaDatiMenu = ref(
      new Menu({
        name: "Banca Dati",
        routeName: "BancaDati",
        routePath: "/main/banca-dati",
        icon: "library",
        divider: false,
        visible: true,
      })
    );
    const codiciMenu = ref(
      new Menu({
        name: "Codici",
        routeName: "Codici",
        routePath: "/codici",
        icon: "book",
        divider: false,
        visible: true,
      })
    );
    const videoMenu = ref(
      new Menu({
        name: "Video",
        routeName: "Video",
        routePath: "/video",
        icon: "film",
        divider: true,
        visible: true,
      })
    );
    const dossierMenu = ref(
      new Menu({
        name: "Fascicoli",
        routeName: "Fascicoli",
        routePath: "/dossier",
        icon: "documents",
        divider: false,
        visible: false,
      })
    );
    const notificheMenu = ref(
      new Menu({
        name: "Notifiche Push",
        routeName: "Notifiche",
        routePath: "/notifiche",
        icon: "notifications",
        divider: false,
        visible: true,
      })
    );
    const loginMenu = ref(
      new Menu({
        name: "Login",
        routeName: "Login",
        routePath: "/login",
        icon: "log-in",
        divider: false,
        visible: true,
      })
    );
    const logoutMenu = ref(
      new Menu({
        name: "Logout",
        routeName: "Logout",
        event: "logout",
        icon: "layers",
        divider: false,
        visible: false,
      })
    );
    const menuItems = ref([
      homeMenu.value,
      newsMenu.value,
      areeTematicheMenu.value,
      massimarioMenu.value,
      formularioMenu.value,
      realTimeMenu.value,
      bancaDatiMenu.value,
      codiciMenu.value,
      videoMenu.value,
      dossierMenu.value,
    ]);

    if (isAndroid || isIosCompliant()) {
      menuItems.value.push(notificheMenu.value);
    }
    menuItems.value.push(loginMenu.value);
    menuItems.value.push(logoutMenu.value);

    watch(isAuthenticated, (authenticated: boolean) => {
      loginMenu.value.visible = !authenticated;
      logoutMenu.value.visible = authenticated;
      dossierMenu.value.visible = authenticated;
      notificheMenu.value.visible = authenticated;
      menuItems.value = [
        homeMenu.value,
        newsMenu.value,
        areeTematicheMenu.value,
        massimarioMenu.value,
        formularioMenu.value,
        realTimeMenu.value,
        bancaDatiMenu.value,
        codiciMenu.value,
        videoMenu.value,
        dossierMenu.value,
      ];
      if (isAndroid || isIosCompliant()) {
        menuItems.value.push(notificheMenu.value);
      }
      menuItems.value.push(loginMenu.value);
      menuItems.value.push(logoutMenu.value);
    });

    function action(to: string, event: string) {
      if (to) {
        location.href = to;
        //router.push(to);
      } else if (event) {
        eventBus.default.$emit(event);
      }
    }



    onMounted(async () => {
      eventBus.default.$on("logout", async function () {
        const idSession = useCookie("SESSIONID").cookie.value;
        const username = useCookie("username").cookie.value;
        if (username && idSession) {
          await JnService.logout(username, idSession, Number(process.env.VUE_APP_PRODUCT_ID))
        }
        paywallCookie.removeCookie();
        usernameCookie.removeCookie();
        idSessionCookie.removeCookie();
        // store.commit('crmInfo', undefined);
        isAuthenticated.value = false;
        eventBus.default.$emit("paywall", true);
        // paywallCookie.setCookie('true');
        //  router.push('/main/home');
        dossierMenu.value.visible = false;
      });
      eventBus.default.$on("login", function (authenticated: boolean) {
        isAuthenticated.value = authenticated;
        dossierMenu.value.visible = authenticated;
        notificheMenu.value.visible = authenticated;
      });
      const { cookie } = useCookie("username");
      if (cookie.value) {
        isAuthenticated.value = true;
        dossierMenu.value.visible = true;
        notificheMenu.value.visible = true;
      } else {
        isAuthenticated.value = false;
        dossierMenu.value.visible = false;
        notificheMenu.value.visible = false;
      }
    });

    return {
      menuItems,
      action,
      isAuthenticated,
      version,
      environment,
    };
  },
});
