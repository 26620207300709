interface PicklistProps {
  value: string;
  label: string;
  checked?: boolean;
}

export class Picklist {
  private props: PicklistProps;

  constructor(props: PicklistProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get value(): string {
    return this.props.value;
  }
  get label(): string {
    return this.props.label;
  }
  set label(label: string) {
    this.props.label = label;
  }
  get checked(): boolean | undefined {
    return this.props.checked;
  }
  set checked(checked: boolean | undefined) {
    this.props.checked = checked;
  }
}
