interface MenuProps {
  name: string;
  routeName: string;
  routePath?: string;
  event?: string;
  icon?: string;
  faIcon?: string;
  divider: boolean;
  visible: boolean;
}

export class Menu {
  private props: MenuProps;

  constructor(props: MenuProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get name(): string {
    return this.props.name;
  }

  get routeName(): string {
    return this.props.routeName;
  }

  get routePath(): string | undefined {
    return this.props.routePath;
  }

  get event(): string | undefined {
    return this.props.event;
  }

  get icon(): string | undefined {
    return this.props.icon;
  }

  get faIcon(): string | undefined {
    return this.props.faIcon;
  }

  get divider(): boolean {
    return this.props.divider;
  }

  get visible(): boolean {
    return this.props.visible;
  }

  set visible(visible: boolean) {
    this.props.visible = visible;
  }
}
